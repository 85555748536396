import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import React, { useEffect, useState } from "react";
// We import NavLink to utilize the react router.
//
import { NavLink } from "react-router-dom";import { Nav, Navbar } from "react-bootstrap";
// We import bootstrap to make our application look better.
import "bootstrap/dist/css/bootstrap.css"
import RecordService from '../service/record.service'
import SiteService from '../service/site.service'




// Here, we display our Navbar
export default function MyNavbar(props) {


  const [subscriptionsCount, setSubscriptionsCount] = useState([]);

  useEffect(() => {
    async function getSubscriptionsCount() {
        RecordService.getSubscriptionsCountById(props.siteId).then(
            (response) => {
//                    alert(response.data)
                setSubscriptionsCount(response.data);
            },
            (error) => {
                const _event =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                console.log("Navbar Error:" + JSON.stringify(error))
                setSubscriptionsCount("N/A");
            }
        );
    }
    

    getSubscriptionsCount();

    const interval = setInterval(() => {
      getSubscriptionsCount()
    }, 10000)


  return () => clearInterval(interval);


  }, [subscriptionsCount.length,props.siteId]);

    return (
      <Navbar collapseOnSelect expand="sm" bg="light" variant="light">
        <Container>
          <Navbar.Brand as={Link} to="/">Sense Solutions Park App</Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" data-bs-target="#navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav>
              <Nav.Link as={Link} to="/create">Abonner</Nav.Link>
              <Nav.Link as={Link} to="/queue">Kø ({subscriptionsCount})</Nav.Link>
              <Nav.Link as={Link} to="/select">Site</Nav.Link>
              <Nav.Link as={Link} to="/debug">Debug</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }


