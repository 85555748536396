//import axios from "axios";
//import authHeader from "./auth-header";
import api from './api';


const getSites = () => {
  return api.get("getsites");
}
const getParkStatus = () => {
  return api.get("parkstatus");
}

const getParkStatusById = (id) => {
//  console.log("Recordservice getParkstatusbyid:" + id)
return api.get("parkstatusbyid",{ params: { "id": id }});
}
const getSubscriptionsCountById = (id) => {
  //  console.log("Recordservice getParkstatusbyid:" + id)
  return api.get("subscriptionscountbyid",{ params: { "id" : id }});
}
  
const getSubscriptionscount = () => {
  return api.get("subscriptionscount")
}


const getSubscriptions = () => {
  return api.get("subscriptions")
}

const getSubscriptionsById = (id) => {
  //  console.log("Recordservice getParkstatusbyid:" + id)
  return api.get("subscriptionsbyid",{ params: { "id" : id }});
}
  
const getSubscribed = (fcmToken) => {
  return api.get("subscriptions", {params: {"fcmToken": fcmToken }})
}

const addSubscriber = (phoneNumber,siteId) => {
  //  axios.post()
//  console.log("record service - subscriptionscount" + phoneNumber)
  return api.post("record/addsite", { "phoneNumber": phoneNumber ,"siteId": siteId})

}

const getHello = () => {
  return "Hello World"
};


const RecordService = {
  getParkStatus,
  getParkStatusById,
  getSubscriptionsCountById,
  getSubscriptions,
  getSubscriptionsById,
  addSubscriber,
  getHello,
  getSites,
};

export default RecordService;