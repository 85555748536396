import React, { useEffect, useState } from "react";
import RecordService from '../service/record.service'
import SiteService from '../service/site.service'
import AuthService from '../service/site.service'
import { useNavigate } from 'react-router-dom';

  


function Site (props)  {
    let navigate = useNavigate();
    const homePage = (callback) => {
        SiteService.setSite(props.subscriber.id);
//        alert(JSON.stringify(props))
        callback(props.subscriber.id)
        navigate("/")
    }
    return(
    <tr>
      
        <td 
            onClick={() => homePage(props.parentCallBack)}
            style={{cursor:'pointer'}}
        >{props.subscriber.name}</td>
      
    </tr>
    );
    }

export default function SelectSite(props) {
    const [sites, setSites] = useState([]);

    // This method fetches the records from the database.

//    alert(JSON.stringify(props))
    // This method will map out the records on the table
    function siteList() {
        return sites.map((sites) => {
            return (
                <Site
                    parentCallBack={props.parentCallBack} subscriber={sites}                
                    key={sites.id}/>
            );
        });
    }
    useEffect(() => {

        async function getSites() {
            console.log("getSites async")
            RecordService.getSites()
                .then(
                    (response) => {
                        console.log(response.data.data)
                        setSites(response.data.data);
                    },
                    (error) => {
                        const _event =
                            (error.response && error.response.data) ||
                            error.message ||
                            error.toString();
                        console.log("getEvents Error:" + JSON.stringify(_event))
                        setSites(
                            [{ "id": "N/A", "name": "N/A", "parent_id": "N/A" },
                            ])
//                        AuthService.logout();
                        //                    setEvents(_event);
                    }
                )
                .catch(
                    (error) => {
                        console.log('There has been a problem with your getsites operation: ' + error.message);
                    }
                )
                ;
        }

        getSites();
/*
        const interval = setInterval(() => {
            getSites()
        }, 10000)
*/
    }, [sites.length]);



    // This following section will display the table with the records of individuals.
    return (
        <div>
            <table className="table table-striped" style={{ marginTop: 20 }}>

                <tbody>{siteList()}</tbody>
            </table>
        </div>
    );
}