import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import MyNavbar from "./components/navbar";
import RecordList from "./components/recordList";
import Edit from "./components/edit";
import Create from "./components/create";
import Queue from "./components/queue";
import Login from "./components/login";
import Debug from "./components/debug"
import SelectSite from "./components/select"
import SiteService from './service/site.service'

import AuthService from "./service/auth.service";
import AuthVerify from "./common/auth-verify";


const App = () => {
  const [currentUser, setCurrentUser] = useState(undefined);
  const [currentSite,setCurrentSite] = useState(0)

  const callBackFunction = (siteId) => {
//    alert("CallbackFunction Called" + siteId)
    setCurrentSite(siteId);
  }

  useEffect(() => {
    async function appLogin() {
      const user = AuthService.getCurrentUser();
      setCurrentSite(SiteService.getSite());
      if (user) {
        setCurrentUser(user);
      } else {
        console.log("Do AuthService.login")
        AuthService.login('webapp', 'webappv1').then(
          () => {
            setCurrentUser(AuthService.getCurrentUser());
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();

            console.log("App login error:" + JSON.stringify(error))
          }
        );
      }
    }

    appLogin()

    // refresh page every 10th seconds
    const interval = setInterval(() => {
      if (currentUser === undefined) {
        appLogin()
      }
    }, 10000)


    return () => clearInterval(interval);

  }, []);

  const logOut = () => {
    AuthService.logout();
  };


  return (
    <div>
      <MyNavbar siteId={currentSite} parentCallBack= {callBackFunction}/>
      <Routes>


        <Route exact path="/" element={<RecordList />} />
        <Route path="/edit/:id" element={<Edit />} />
        <Route path="/create" element={<Create />} />
        <Route path="/queue" element={<Queue />} />
        <Route path="/select" element={
          <SelectSite parentCallBack={callBackFunction} />
          } />
        <Route path="/debug" element={<Debug />} />

      </Routes>

    </div>
  );
};

export default App;