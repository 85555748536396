import api from './api';

const getSite = () => {

  const siteId = localStorage.getItem("site");
  console.log("getSite called - result is " + siteId)
  return siteId;
};


const getSites = (siteId) => {
  const sitesStr = localStorage.getItem("sites");
  const sitesArray = []
  if (sitesStr) {
    sitesArray = JSON.parse(sitesStr);
  }
  return sitesArray;
};

const addSite = (siteId) => {
  const sitesStr = localStorage.getItem("sites");
  const sitesArray = []
  if (sitesStr) {
    sitesArray = JSON.parse(sitesStr);
  }
  sitesArray.push(siteId)
  return (localStorage.setItem(JSON.stringify(sitesArray)));
};

const removeSite = (siteId) => {
  const sitesStr = localStorage.getItem("sites");
  const sitesArray = [];
  if (sitesStr) {
    sitesArray = JSON.parse(sitesStr)

    const newSitesArray = sitesArray.filter(
      function (item) {
        return item !== siteId;
      }
    )
    
    return localStorage.setItem("sites",JSON.stringify(newSitesArray));
  } else {
    return ( false );
  }

};


const unsetSite = () => {
    localStorage.removeItem("site");
};


const setSite = (siteId) => {
  console.log("SiteService.setSite(" + siteId + ") called.")
  localStorage.setItem("site", siteId);
};


const SiteService =  {
  getSite,
  setSite,
  unsetSite,
  getSites,
  addSite,
  removeSite,
}

export default SiteService;