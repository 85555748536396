import axios from "axios";
import TokenService from "./token.service";
const { REACT_APP_SERVERURL } = process.env;


const instance = axios.create({
  baseURL: REACT_APP_SERVERURL,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      //        console.log(JSON.stringify(token))
      config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
      //config.headers["x-access-token"] = token; // for Node.js Express back-end
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== "/login" && err.response) {
      console.log("Access Token was Expired")
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          console.log("Try refreshtoken")
          const refreshtoken = TokenService.getLocalRefreshToken();
          if (refreshtoken) {
            const rs = await instance.post("/refreshtoken", {
              "refreshToken": refreshtoken,
            }, originalConfig);


            const { accessToken, refreshToken } = rs.data;
            //TokenService.updateLocalAccessToken(accessToken);
            TokenService.setUser({
              "accessToken": accessToken,
              "refreshToken": refreshToken,
            })
            return instance(originalConfig);
          } else {
//            TokenService.removeUser();
            return Promise.reject(err);
          }

        } catch (_error) {
          console.log("Dammit - caught an error:" + JSON.stringify(_error))
          TokenService.removeUser();
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);

export default instance;