import RecordService from './record.service';

const getSubscription = () => {

  const subscriptionStr = localStorage.getItem("subscription");
  console.log("getSubscription called - result is " + subscriptionStr)
  return subscriptionStr;
};


const removeSubscription = () => {
    localStorage.removeItem("subscription");
};


const setSubscription = (subscriptionStr) => {
  console.log("SubscriptionService.setSubscription(" + subscriptionStr + ") called.")
  localStorage.setItem("subscription", subscriptionStr);
};

const isSubscribedAtServer = (subscriptionStr) => {
//  RecordService.

}

const SubscriptionService =  {
  getSubscription,
  setSubscription,
  removeSubscription,
}

export default SubscriptionService;