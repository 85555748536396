import api from "./api";
import TokenService from "./token.service";

const register = (username, email, password) => {
    return api.post("/auth/signup", {
        username,
        email,
        password
    });
};

const login = (username, password) => {
    try {
    return api
        .post("/login", {
            username,
            password
        })
        .then((response) => {
            
            if (response.data.accessToken) {
                TokenService.setUser(response.data);
            }

            return response.data;
        });
    } catch (err) {
        console.log("login.api.err" + JSON.stringify(err))
    }
};

const logout = () => {
    TokenService.removeUser();
};

const getCurrentUser = () => {
    return TokenService.getUser();
};




const AuthService = {
    register,
    login,
    logout,
    getCurrentUser,
};

export default AuthService;