import React, { useState } from "react";
import { useNavigate } from "react-router";


import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import RecordService from "../service/record.service";
import SiteService from "../service/site.service";

//let errors ="No Errors in form";
const Spacer = props => {
  return (
    <div style={{height:props.height}}></div>
  );
}

export default function Create() {
  const [phoneNumber, setPhonenumber] = useState();

  const [errors, setErrors] = useState({
    errorStr: "",
  });
  const navigate = useNavigate();

  function validatePhoneForE164(phoneNumber) {
    const regEx = /^\+[1-9]\d{9,14}$/;
    if (!regEx.test(phoneNumber)) {
      return("Ugyldigt Mobilnummer: " + phoneNumber + " - Husk landekode - for eksempel +4521222324");
    } else {
      return "";
    }
  };

  // These methods will update the state properties. 
  function updateErrors(value) {
    return setErrors((prev) => {
      return { ...prev, ...value };
    });
  }

/*
  function updateForm(value) {
    return setForm((prev) => {
      return { ...prev, ...value };
    });

  }
*/
  // This function will handle the submission.
  async function onSubmit(e) {
    e.preventDefault();

    // When a post request is sent to the create url, we'll add a new record to the database.
    //const phoneNumber = { ...};
//    alert(JSON.stringify(phoneNumber))
    //phoneNumber.phoneNumber=phoneNumber.phoneNumber.replace(/\s/g, '');
    const errormsg = validatePhoneForE164(phoneNumber);
    if (errormsg) {
      updateErrors({  errorStr: errormsg });
      return;
    }

    /* construct json object to send to server */
//    const phoneNumberObj = {"phoneNumber": phoneNumber}
    //console.log(JSON.stringify(errormsg))
    //console.log("errors.errormsg:" + JSON.stringify(errors))

//    alert(JSON.stringify(phoneNumber))

//    alert(JSON.stringify(phoneNumberObj))
  console.log("before Recordservice.addSubscriber")
  const siteId=SiteService.getSite();
  RecordService.addSubscriber(phoneNumber,siteId)
  /*  
    await fetch(REACT_APP_SERVERURL + "record/add", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(phoneNumberObj),
    })*/
      .catch(error => {
        window.alert(error);
        return;
      });

  /*  setPhonenumber("");*/
    navigate("/");
  }

  // This following section will display the form that takes the input from the user.
  return (

    <div>
      <h3>Abonner på ledig plads</h3>

      <form onSubmit={onSubmit}>
        <div className="form-group">
        <Spacer height="1rem" />
          {/*<label htmlFor="phoneNumber">Mobilnummer til SMS</label>*/}
          <PhoneInput
            defaultCountry="DK"
            className="form-control"
            id="phoneNumber"
            placeholder="xx xx xx xx        (indtast mobilnummer)"
            value={phoneNumber}
            onChange={setPhonenumber}
          />
          <Spacer height="1rem" />
        <div>{errors.errorStr}</div>
        <button className="btn btn-primary">Tilmeld</button>  
        </div>
      </form>
    </div>
  );
}