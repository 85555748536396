import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom';
import { faHourglass,faBell,faBellSlash,faBan, faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import RecordService from '../service/record.service'
import SiteService from '../service/site.service'
const occupiedStyle =  {
//    color: "",
    color: "#DD0000",
}

const freeStyle =  {
    //    color: "",
        color: "#00AA00",
}
const unknownStyle =  {
    //    color: "",
        backgroundColor: "#FFFF00",
}

function Event(props) {

    const [isShown, setIsShown] = useState(false);

    const handleClick = event => {
    // 👇️ toggle shown state
    setIsShown(current => !current);

    // 👇️ or simply set it to true
    // setIsShown(true);
    };


    let tdStyle=freeStyle; //assume parking spot is free (green)
    const HoursOld=Math.round(((Date.now()/1000 - props.event.timestamp/1000)/3600)); // convert timestamp to hours
    let title=(props.event.timestamp)?'Optaget':'Ledig';
    if (HoursOld > 24) 
    {
        tdStyle=unknownStyle; // make parking spot yellow to indicate missing data
    } else {
        if (props.event.occupied) {
            tdStyle=occupiedStyle; // or else it is red for occupied
        }
    }
    let iconHourGlass = ""; // no hourglass by default
    let iconStatus = <FontAwesomeIcon style={tdStyle} icon={faCircleCheck}/>

    if (props.event.occupied) {
        iconStatus = <FontAwesomeIcon style={tdStyle} icon={faBan}/>
        if (HoursOld < 0 ) {
            iconHourGlass = <FontAwesomeIcon icon={faBellSlash} style={tdStyle}/>
        } else if (HoursOld<5 ) {
            iconHourGlass = <FontAwesomeIcon icon={faHourglass} style={tdStyle}/>
        } else if (HoursOld<24) {
            iconHourGlass = <FontAwesomeIcon icon={faBell} style={tdStyle}/>
        } else if (HoursOld >= 24) {
            iconHourGlass = <FontAwesomeIcon icon={faBellSlash} style={tdStyle}/>
        }
    } else {
        iconHourGlass = <FontAwesomeIcon style={tdStyle} icon={faCircleCheck}/>
    }
    return(
        <tr>
            <td style={{ width:20 }} onClick={handleClick} title={title}>{!isShown?iconHourGlass:HoursOld}</td>
            <td onClick={handleClick}> {props.event.device_name} </td>
        </tr>
    )
}


const EventList = () => {
    const [events, setEvents] = useState([]);
    let navigate = useNavigate();
    // This method fetches the records from the database.
    useEffect(() => {

        if(!SiteService.getSite()) {
            navigate('/select');
          } 
        async function getEvents() {
            RecordService.getParkStatusById(SiteService.getSite())
            .then(
                (response) => {
//                    alert(response.data)
                    setEvents(response.data);
                },
                (error) => {
                    const _event =
                        (error.response && error.response.data) ||
                        error.message ||
                        error.toString();
                    console.log("getEvents Error:" + JSON.stringify(error))
                    setEvents( 
                        [{"_id":"N/A","EUI":"N/A","device_name":"N/A","occupied":true,"timestamp":0,"bat":0},
                    ])
//                    setEvents(_event);
                }
            );
        }

        getEvents();

        // refresh page every 10th seconds
        const interval = setInterval(() => {
            getEvents()
        }, 10000)


        return () => clearInterval(interval);
        //   return;
        
    }, [events.length]);


    // This method will map out the records on the table
    function eventList() {
        return events.map((event) => {
            return (
                <Event
                    event={event}
                    key={event._id}
                />
            );
        });
    }

    // This following section will display the table with the records of individuals.
    return (
        <div>
            <table className="table " style={{ marginLeft:10,marginTop: 20 }}>
                <tbody>{eventList()}</tbody>
            </table>
        </div>
    );
}

export default EventList;