import React, { useEffect, useState } from "react";
import AuthService from '../service/auth.service'
import SiteService from "../service/site.service";
import SubscriptionService from "../service/subscription.service";

export default function Debug() {

    // This following section will display the table with the records of individuals.
    return (
        <div>
            
            <div>Debug</div>
            <div style={{ overflowWrap: 'break-word'}}>User:{JSON.stringify(AuthService.getCurrentUser())}</div>
            <div style={{ overflowWrap: 'break-word'}}>Site:{JSON.stringify(SiteService.getSite())}</div>
            <div style={{ overflowWrap: 'break-word'}}>Site:{JSON.stringify(SubscriptionService.getSubscription())}</div>
        </div>
    );
}