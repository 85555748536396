import React, { useEffect, useState } from "react";
import RecordService from '../service/record.service'
import SiteService from "../service/site.service";


const Subscriber = (props) => (
    <tr>
      
        <td>{(props.subscriber.phonenumber)?props.subscriber.phonenumber.substring(1,7)+"XXXX":"Push Subscription"}</td>
      
    </tr>
);

export default function Queue() {
    const [subscribers, setSubscribers] = useState([]);

    // This method fetches the records from the database.
    useEffect(() => {
 
        const siteId= SiteService.getSite();
        async function getSubscribers() {
            RecordService.getSubscriptionsById(siteId).then(
                (response) => {
                    setSubscribers(response.data);
                },
                (error) => {
                    const _event =
                        (error.response && error.response.data) ||
                        error.message ||
                        error.toString();
                    setSubscribers([])
                    console.log("getSubscribers Error:" + JSON.stringify(error))
                }
            );
        }
        getSubscribers();

        const interval = setInterval(() => {
            getSubscribers()
        }, 10000)


        return () => clearInterval(interval);
        //   return;
    }, [subscribers.length]);


    // This method will map out the records on the table
    function subscriberList() {
        return subscribers.map((subscriber) => {
            return (
                <Subscriber
                    subscriber={subscriber}
                    key={subscriber._id}
                />
            );
        });
    }

    // This following section will display the table with the records of individuals.
    return (
        <div>
            <table className="table table-striped" style={{ marginTop: 20 }}>

                <tbody>{subscriberList()}</tbody>
            </table>
        </div>
    );
}