import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import TokenService from "../service/token.service";
const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

const AuthVerify = (props) => {
  let location = useLocation();

  useEffect(() => {
    const user = TokenService.getLocalAccessToken()
//    console.log(JSON.stringify(user))

    if (user) {
      const decodedJwt = parseJwt(user);

      if (decodedJwt.exp * 1000 < Date.now()) {
        props.logOut();
      }
    }
  }, [location, props]);

  return ;
};

export default AuthVerify;